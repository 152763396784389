<template>
    <div :class="'m-p profile-container ' + ( this.$store.state.profileSideBar ? 'open' : '' )">
       <Support />
    </div>
 </template>
 <script>
 import { defineAsyncComponent } from 'vue'
 export default {
 name: 'Support Center',
 components: {
    Support: defineAsyncComponent( () => import('@/components/Profile/Support/Support.vue') ),
 },
 }
 </script>